.swal {
  width: 1000px !important;
}
.base-container {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  .login-button {
    padding-top: 100px;
    button {
      padding: 10px;
      border-radius: 4px;
      border-width: 0;
      font-size: 15px;
      color: white;
      font-weight: bold;
      background-color: #7cd1f9;
      //   box-shadow: 0px 0px 50px 2px rgba(15, 15, 15, 0.2);
      &:hover {
        box-shadow: 0px 0px 10px 0.2px #373a3c;
      }
    }
  }
  .Calendar-container {
    margin-top: 20px;
    height: 600px;
    padding: 0 20px 0;
    width: 90%;
    border-radius: 6px;
    button {
      padding: 10px;
      background-color: white;
      border-radius: 8px;
      button > label {
        display: none;
      }
      &:hover {
        box-shadow: 0px 0px 10px 0.2px #373a3c;
      }
    }
  }
}
