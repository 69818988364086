.alert-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  height: 450px;
  .image {
    width: 50%;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 450px;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;

      label {
        font-size: 18px;
      }

      input {
        margin-top: 6px;
        min-width: 25rem;
        height: 37px;
        padding: 5px 20px;
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        background-color: #f3f3f3;
        border: 0;
        border-radius: 6px;
        margin-bottom: 25px;
        &:focus {
          outline: none;
          box-shadow: 0px 0px 15px 0.8px #0e81ce96;
        }
      }
    }
  }
}
