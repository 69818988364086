.main-container {
  width: 100%;
  .row1 {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 20px;
  }
  .row2 {
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    padding-bottom: 20px;
  }
  .row3 {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
  }
  .row4 {
    display: flex;
    flex-direction: row;
  }
  .row5 {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
  .footer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 10px;
    .requestButton {
      padding: 0 30px;
    }
  }
  .footer {
    padding-top: 20px;
    justify-content: center;
  }
}
